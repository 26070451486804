import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0609B1",
      dark: "#2196f3",
      light: "#90caf9",
    },
    error: {
      main: "#D32F2F",
    },
    white: {
      main: "#FFFFFF",
    },
  },
  shape: {
    borderRadius: 6,
  },
  breakpoints: { values: { xs: 0, sm: 600, md: 901, lg: 1513, xl: 1920 } },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
            minWidth: 0,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          letterSpacing: "0.4px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorError: { backgroundColor: "#FDEDED", color: "#C62828" },
        colorSuccess: { backgroundColor: "#EDF7ED", color: "#1B5E20" },
        root: { backgroundColor: "#00000014", color: "#000000DE" },
      },
    },
  },
  typography: {
    h1: { fontFamily: "DegularText-Medium" },
    h2: { fontFamily: "DegularText-Medium" },
    h3: {
      fontFamily: "DegularText-Medium",
      fontWeight: "600",
      fontSize: "48px",
      lineHeight: "116.7%",
      letterSpacing: "0px",
      "@media (max-width:1512px)": {
        fontSize: "40px",
      },
    },
    h4: {
      fontFamily: "DegularVariable",
      fontWeight: "600",
      fontSize: "34px",
      lineHeight: "123.5%",
      letterSpacing: "0.25px",
      "@media (max-width:1512px)": {
        fontSize: "28px",
      },
    },
    h5: { fontFamily: "DegularText-Medium" },
    h6: {
      fontFamily: "Roboto",
      fontWeight: "400",
      fontSize: "19px",
      lineHeight: "160%",
      letterSpacing: "0.15px",
      "@media (max-width:1512px)": {
        fontSize: "18px",
        lineHeight: "28.8px",
      },
    },
    body1: {
      fontSize: "16px",
      lineHeight: "150%",
      letterSpacing: "0.15px",
      "@media (max-width:1512px)": {
        fontSize: "14px",
      },
    },
    body2: {
      color: "rgba(0, 0, 0, 0.6)",
    },
    subtitle1: {
      color: "rgba(0, 0, 0, 0.60)",
      "@media (max-width:1512px)": {
        fontSize: "14px",
      },
    },
    subtitle2: {
      fontSize: "14px",
      fontWeight: 500,
      fontFamily: "Roboto",
      color: "rgba(0, 0, 0, 0.60)",
    },
    overline: {
      fontSize: "12px",
      lineHeight: "266%",
      letterSpacing: "1px",
      fontWeight: "400",
      opacity: "60%",
      "@media (max-width:1512px)": {
        fontSize: "10px",
      },
    },
  },
});

export default theme;

declare module "@mui/material/styles" {
  // noinspection JSUnusedGlobalSymbols
  interface Palette {
    white: Palette["primary"];
  }

  // noinspection JSUnusedGlobalSymbols
  interface PaletteOptions {
    white?: PaletteOptions["primary"];
  }
}
declare module "@mui/material/IconButton" {
  // noinspection JSUnusedGlobalSymbols
  interface IconButtonPropsColorOverrides {
    white: true;
  }
}
